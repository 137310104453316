import React, {useState, useEffect, Fragment} from 'react'
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActions } from '@mui/material';
import Typography from '@mui/material/Typography';
import CardContent from '@mui/material/CardContent';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@mui/material';
import Divider from '@mui/material/Divider';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import { Button } from '@mui/material';
import { useMutation } from "react-query";
import apiClient from '../../request/http-common';
import CircularProgress from '@mui/material/CircularProgress';
import { useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useSelector } from 'react-redux';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useQuery } from 'react-query';


import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PendingIcon from '@mui/icons-material/Pending';
import DangerousIcon from '@mui/icons-material/Dangerous';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';

const Shareholders = () => {
    const dispatch = useDispatch();
    const initialLogin = {
        shareholder_type : ''	,
    }
    
    const [error, setErros] = useState('');
    const [planForm, setPlanForm] = useState(initialLogin);
    const cards = useSelector((state) => state.history.cards);
    const shareholders = useSelector((state) => state.history.shareholders);

    const onChange = (e) =>
    setPlanForm({ ...planForm, [e.target.name]: e.target.value });


    const { isLoading: isSendingRequest, mutate: postPlan } = useMutation(

        async () => {
          return await apiClient.post(`/api/shareholder-application`, {
            type : planForm.shareholder_type,	

          });
        },
        {
          onSuccess: (res) => {
            localStorage.setItem('notification', JSON.stringify({message : `${res.data.message}`, 'type': 'success' }));
            const event = new Event('newMessage');
            window.dispatchEvent(event);
            const event2 = new Event('processed');
            window.dispatchEvent(event2);
          },
          onError: (err) => {   
            let myerror = err.response?.data || err;         
            setErros(myerror.errors)  
            const event2 = new Event('processed');
            window.dispatchEvent(event2);     
          },
        }
      );

      const onSubmit = () =>{
        const event3 = new Event('processing');
        window.dispatchEvent(event3);
        postPlan();
      } 

      useEffect(() => {
        document.title = 'SHAREHOLDERS';
      }, []);

  return (
    <div style={{marginTop: '70px'}}>
        <Grid container px={2} mt={4}  mb={4} rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }}>
            <Grid item xs={12} md={12}>
                <Card sx={{marginX: '5px', borderRadius: '9px', height: '100%'}}>
                    <CardContent>
                    <Typography mt={4} sx={{ fontWeight: 'bold', fontSize: 24, textAlign: 'center'  }} gutterBottom variant="h6" component="div">
                    BECOME A SHAREHOLDER
                    </Typography>
                    <Typography  sx={{  textAlign: 'center', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    Note: kindly note that only users who have participated in the Last plan are eligible to apply.
                    </Typography>

                    <FormControl fullWidth={true} sx={{ m: 0, minWidth: 50, marginBottom: 2, marginTop: 5 }}>
                    <InputLabel htmlFor="shareholder_type">Shareholder Type</InputLabel>
                    {(error !== '' && error.shareholder_type) ?
                        <Select
                            error
                            onChange={onChange}
                            labelId="shareholder_type"
                            id="shareholder_type"
                            name="shareholder_type"
                            label={`Shareholder Type`}
                            helperText={error.shareholder_type}
                            >
                            <MenuItem selected value=''>
                            Shareholder Type
                            </MenuItem> 
                            <MenuItem selected value="Equity shareholders">
                            Equity shareholders
                            </MenuItem>
            
                            <MenuItem selected value="Debenture shareholder">
                            Debenture shareholder
                            </MenuItem>

                            <MenuItem selected value="preference shareholders">
                            preference shareholders
                            </MenuItem>             
                            
                        </Select>
                    :
                        <Select
                            onChange={onChange}
                            labelId="shareholder_type"
                            name="shareholder_type"
                            id="shareholder_type"
                            label={`Shareholder Type`}
                            >
                            <MenuItem selected value=''>
                            Shareholder Type
                            </MenuItem> 
                             <MenuItem selected value="Equity shareholders">
                            Equity shareholders
                            </MenuItem>
            
                            <MenuItem selected value="Debenture shareholder">
                            Debenture shareholder
                            </MenuItem>

                            <MenuItem selected value="preference shareholders">
                            preference shareholders
                            </MenuItem> 
                        </Select>
                    }
                    <Typography color='error'  sx={{  textAlign: 'left', fontSize: 16  }} variant="subtitle1" gutterBottom>
                    {error !== '' && error.shareholder_type}
                    </Typography>
                </FormControl>
                        
                    </CardContent>   

                    <CardActions>
                        <Button onClick={onSubmit} fullWidth={true} size="large" color="primary" variant="contained"> {isSendingRequest? 'Sending...' : 'Send Request'}</Button>
                    </CardActions>
                </Card>
            </Grid>

            <Grid item xs={12} md={4}>
            {shareholders.length > 0 &&
        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>

        {shareholders.map(shareholder => (
        <Fragment key={shareholder.id}>
        <ListItem sx={{cursor: 'pointer'}} alignItems="flex-start">
        <ListItemAvatar>
            
            {shareholder.status === 'pending' &&
            <Avatar  sx={{ bgcolor: '#f7a21d' }}>
            <PendingIcon/>
            </Avatar>
            }
            
            {shareholder.status === 'approved' &&
            <Avatar  sx={{ bgcolor: '#37dc8a' }}>
            <CheckCircleIcon/>
            </Avatar>
            }

            {shareholder.status === 'declined' &&
            <Avatar  sx={{ bgcolor: '#dd0a22' }}>
            <DangerousIcon/>
            </Avatar>
            
            }

            {shareholder.status === 'completed' &&
            <Avatar  sx={{ bgcolor: '#ccc6c6' }}>
            <DoneAllIcon/>
            </Avatar>
            
            }
            
            
        </ListItemAvatar>

        <ListItemText
            primary={`${shareholder.type}`}
            secondary={
            <React.Fragment>
                <Typography
                sx={{ display: 'inline' }}
                component="span"
                variant="body2"
                color="text.primary"
                >
                {/* {shareholder.card_type} */}
                </Typography>
            </React.Fragment>
            }
        />
        

            <Box edge="end" sx={{display: 'flex', flexDirection: 'column', justifyContent: 'space-even', alignContent: 'center', alignItems: 'right'}}>
                <Typography color={shareholder.status === 'pending'? 'secondary': 'primary.success'}  edge="end">
                {shareholder.status}
                </Typography>
            </Box>

        
        </ListItem>
        <Divider  component="li" variant="inset" />
        </Fragment>
        ))}
        </List>
        }
            </Grid>
        </Grid>
    </div>
  )
}

export default Shareholders
